<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="brands"
    :loading="brandStatusRequest.value === 'loading-getBrands'"
    calculate-widths
    class="tp-table-scroll__with-title tp-table__row-pointer datatable px-3 py-2"
    disable-pagination
    hide-default-footer
    item-key="id"
    loading-text="Đang tải dữ liệu"
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    show-select
    @click:row="viewDetail($event)"
  >
    <template v-slot:[`item.cover`]="{ item }">
      <v-img
        :aspect-ratio="2"
        :src="item.cover"
        class="tp-border-thin rounded-sm my-2"
        height="60px"
        width="120px"
      >
        <template v-slot:placeholder>
          <v-row align="center" class="fill-height ma-0" justify="center">
            <v-img :aspect-ratio="2" :src="defaultImage"></v-img>
          </v-row>
        </template>
      </v-img>
    </template>

    <template v-slot:[`item.icon`]="{ item }">
      <v-img
        :src="item.icon"
        class="tp-border-thin rounded-sm my-2"
        max-height="60px"
        max-width="60px"
        min-height="40px"
        min-width="40px"
      >
        <template v-slot:placeholder>
          <v-row align="center" class="fill-height ma-0" justify="center">
            <v-img :src="defaultImage" height="100%" width="100%"></v-img>
          </v-row>
        </template>
      </v-img>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    selectedBrands: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      defaultImage: require("@/core/assets/images/default-image.png"),
      headers: [
        {
          text: "Mã dịch vụ",
          align: "start",
          sortable: false,
          value: "code"
        },
        {
          text: "Tên dịch vụ",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "Nhóm dịch vụ",
          align: "start",
          sortable: false,
          value: "cover"
        },
        {
          text: "Mô tả",
          align: "start",
          sortable: false,
          value: "icon"
        },
        {
          text: "Đơn giá",
          align: "start",
          sortable: false,
          value: "description"
        },
        {
          text: "Trạng thái",
          align: "start",
          sortable: false,
          value: "description"
        }
      ],
      items: [
        {
          code: "TH20423",
          brand_name: "LG",
          cover: "https://picsum.photos/200/300",
          logo: "https://picsum.photos/30/40",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper scelerisque lorem, quis dictum justo pretium eget"
        }
      ]
    };
  },
  computed: {
    brand() {
      return this.$store.getters["BRAND/brand"];
    },
    brands() {
      return this.$store.getters["BRAND/brands"];
    },
    brandStatusRequest() {
      return this.$store.getters["BRAND/statusRequest"];
    },
    selected: {
      get() {
        return this.selectedBrands;
      },
      set(val) {
        this.$emit("updateSelectedBrands", val);
      }
    }
  },
  filters: {
    formatCurrency(value) {
      // Create our number formatter.
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    copyToClipboard(str) {
      const el = document.createElement("textarea");

      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Đã sao chép vào bộ nhớ tạm"
        }
      });
    },

    async viewDetail(item) {
      await this.$store.dispatch("BRAND/getBrandById", item.id);
      if (this.brand.sub_brands.length > 0) {
        await this.$store.dispatch(
          "BRAND/getSubBrandById",
          this.brand.sub_brands[0].id
        );
      }
      this.$modal.show({
        name: "modal-brand-detail"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }

  .copied-label {
    cursor: pointer;
    display: inline-block;
  }

  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}
</style>
