<template>
  <tp-modal name="modal-brand-detail" width="70%" max-width="960px">
    <v-card flat>
      <!-- Start: Title and actions -->
      <v-card-title class="pb-4">
        <div class="font-weight-bold">
          Tạo dịch vụ
        </div>

        <v-spacer></v-spacer>

        <v-btn
          color="red lighten-5 red--text rounded-lg text--accent-2 mr-2"
          depressed
        >
          Hủy
        </v-btn>

        <v-btn class="cyan lighten-5 rounded-lg mr-2" color="primary" text>
          Tạo
        </v-btn>
        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!-- End: Title and actions -->
      <!-- Start: Main content -->
      <v-container class="grey lighten-3 pa-5" fluid>
        <v-card flat class="px-5 pt-5">
          <v-row>
            <v-col class="py-0" cols="12">
              <div class="font-weight-bold mb-2">Tên dịch vụ</div>
              <v-text-field
                class="text-body-1"
                background-color="white"
                dense
                filled
                outlined
                placeholder="Nhập tên dịch vụ"
                single-line
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" cols="6">
              <div class="font-weight-bold mb-2">Trạng thái dịch vụ</div>
              <v-select
                class="text-body-1"
                dense
                :menu-props="{ nudgeTop: '-40px' }"
                item-text="text"
                item-value="value"
                :items="serviceStatusItems"
                outlined
                placeholder="Chọn trạng thái"
              ></v-select>
            </v-col>
            <v-col class="py-0" cols="6">
              <div class="font-weight-bold mb-2">Nhóm dịch vụ</div>
              <v-select
                class="text-body-1"
                dense
                :menu-props="{ nudgeTop: '-40px' }"
                item-text="text"
                item-value="value"
                :items="serviceGroupItems"
                outlined
                placeholder="Chọn nhóm"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" cols="12">
              <div class="font-weight-bold mb-2">Ghi chú</div>
              <v-textarea
                class="text-body-1"
                background-color="white"
                dense
                filled
                outlined
                placeholder="Mô tả về loại dịch vụ"
                single-line
                required
                rows="3"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="mt-5">
            <v-col class="py-0" cols="6"></v-col>
            <v-col class="py-0" cols="6">
              <div class="font-weight-bold mb-2">Giá dịch vụ</div>
              <tp-input-price
                custom-class="text-body-1"
                flat
                dense
                outlined
                placeholder="0"
                solo
                single-line
              ></tp-input-price>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
      <!-- End: Main content -->
    </v-card>
  </tp-modal>
</template>

<script>
export default {
  data() {
    return {
      serviceStatusItems: [
        { value: 0, text: "Ngừng kinh doanh" },
        { value: 1, text: "Kinh doanh" }
      ],
      serviceGroupItems: [
        { value: 1, text: "Dịch vụ phần mềm" },
        { value: 2, text: "Dịch vụ phần cứng" }
      ]
    };
  },
  computed: {
    brand() {
      return this.$store.getters["BRAND/brand"];
    },
    brandStatusRequest() {
      return this.$store.getters["BRAND/statusRequest"];
    }
  },
  methods: {
    closeModal() {
      this.$store.dispatch("BRAND/resetBrand");
      this.$store.dispatch("BRAND/resetSubBrand");
      this.$modal.hide({
        name: "modal-brand-detail"
      });
      this.activeTab = null;
      this.activeSubBrand = 0;
    },

    deleteBrand() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa thương hiệu <strong>"${this.brand.name}"</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              const routeQuery = this.$route.query;

              await this.$store.dispatch("BRAND/deleteBrands", {
                data: {
                  ids: [this.brand.id]
                },
                routeQuery: {
                  search: routeQuery.search,
                  per_page: parseInt(routeQuery.limit),
                  cur_page: parseInt(routeQuery.page)
                }
              });
              if (this.brandStatusRequest.value === "success-deleteBrands") {
                this.$modal.hide({ name: "modal-brand-detail" });
                this.$store.dispatch("BRAND/resetBrand");
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa thành công"
                  }
                });
              }
            }
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.tab-item {
  min-height: 500px;
}
</style>
