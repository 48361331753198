<template>
  <div class="action mr-5 mb-4">
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between">
      <div class="action-row--col flex-grow-1 pr-5">
        <tp-search-option
          :options="searchOptions"
          :value="searchKey"
          @search="updateSearchKey($event)"
        ></tp-search-option>
      </div>
      <div class="action-row--col">
        <v-btn
          class="rounded-lg"
          color="primary"
          depressed
          outlined
          @click="openModalBrandDetail()"
        >
          <v-icon left>mdi-plus</v-icon> Thêm dịch vụ
        </v-btn>
        <v-btn class="ml-3 rounded-lg" color="primary" dark depressed>
          <v-icon left>mdi-file-upload-outline</v-icon> Nhập file
        </v-btn>
      </div>
    </div>
    <!-- End: Action row -->
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between mt-4">
      <div class="action-row--col align-center">
        <!-- Start: Show filter dropdown -->
        <tp-btn-overflow
          btn-class="white rounded-lg"
          :items="showFilterList"
          :selected-value="selectedShowFilterId"
          @change="selectedShowFilterIndex = $event"
          @select="selectShowFilter($event)"
        ></tp-btn-overflow>
        <!-- End: Show filter dropdown -->
        <!-- Start: Action dropdown -->
        <tp-btn-overflow
          v-if="selectedBrands.length > 0"
          btn-class="white rounded-lg ml-3"
          :active-item="false"
          :items="actionList"
          placeholder="Thao tác"
          @select="selectAction($event)"
        ></tp-btn-overflow>
        <!-- End: Action dropdown -->
        <div class="ml-3" v-if="selectedBrands.length > 0">
          Đã chọn <strong>{{ this.selectedBrands.length }}</strong> bản ghi
        </div>
      </div>
      <div class="action-row--col d-flex align-center">
        <div class="text-body-2">
          {{ paginationInfo.from }} - {{ paginationInfo.to }} trong số
          {{ paginationInfo.itemTotal }}
        </div>
        <v-pagination
          v-model="curPage"
          color="primary"
          :length="paginationInfo.pageTotal"
          :total-visible="5"
        ></v-pagination>
      </div>
    </div>
    <!-- End: Action row -->
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number
    },
    selectedBrands: {
      type: Array,
      required: true
    },
    selectedShowFilterId: {
      type: Number
    },
    searchKey: {
      type: String
    }
  },
  data() {
    return {
      actionList: [{ id: "delete", text: "Xóa" }],
      searchOptions: [{ type: "search", label: "Tìm kiếm theo tên dịch vụ" }],
      showFilterList: [
        { id: 25, text: "Hiển thị 25" },
        { id: 50, text: "Hiển thị 50" },
        { id: 100, text: "Hiển thị 100" }
      ]
    };
  },
  computed: {
    brandStatusRequest() {
      return this.$store.getters["BRAND/statusRequest"];
    },
    curPage: {
      get() {
        return this.currentPage;
      },
      set(val) {
        this.$emit("updateCurrentPage", val);
      }
    },
    paginationInfo() {
      return this.$store.getters["BRAND/paginationInfo"];
    }
  },
  methods: {
    async openModalBrandDetail() {
      await this.$store.dispatch("BRAND/resetBrand");
      this.$modal.show({ name: "modal-brand-detail" });
    },

    selectAction(val) {
      if (val.id === "delete") {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span>Xóa <strong>"${this.selectedBrands.length}"</strong> thương hiệu đã chọn</span>`,
            message:
              "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
            confirmBtn: {
              color: "red accent-2",
              text: "Xóa",
              onClickHandler: async () => {
                const routeQuery = this.$route.query;

                await this.$store.dispatch("BRAND/deleteBrands", {
                  data: {
                    ids: this.selectedBrands.map(item => item.id)
                  },
                  routeQuery: {
                    search: routeQuery.search,
                    per_page: parseInt(routeQuery.limit),
                    cur_page: parseInt(routeQuery.page)
                  }
                });
                if (this.brandStatusRequest.value === "success-deleteBrands") {
                  this.$emit("updateSelectedBrands", []);
                  // Alert
                  this.$toast.show({
                    name: "toast-action-alert",
                    payload: {
                      message: "Đã xóa thành công"
                    }
                  });
                }
              }
            }
          }
        });
      }
    },

    selectShowFilter(val) {
      this.$emit("updateItemPerPage", val.id);
    },

    updateSearchKey(val) {
      this.$emit("updateSearchKey", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.action {
  &-row {
    &--col {
      display: flex;
    }
  }
}
</style>
